import { firebase } from '../configs/firebase';


export const CopiarOperadoresEntreCuentas=async()=>{
    let uidorigen="1W3ZQxsEpGUo2uEsB5hMgJFiFAj2";
    let uiddestino="adCOj3hocBYbS1Z69GOptc0ukOr1";
    let aux=[];
    await getOperadores(uidorigen).then(snapshot=>{
        snapshot.forEach(document=>{
           let o= {...document.data()};
           o.uid=uiddestino;
           aux.push(o);
        });

    }).catch(err=>console.log(err));


    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    aux.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}

export const ActualizacionMasivaTemporal = async () => {
    const parquevehicular = [];
    const fixedunidades=parquevehicular.map(element=>{
        let obj = {
            nombre:element.Nombre||"",
            apellidos:element.Apellidos||"",
            ciudad:"",
            celular:element.Telefono||"",
            tipoDePago:"Manual",
            active:true,
            noLicencia:element.NumeroLicencia||"",
            tipoLicencia:element.TipoLicencia||"",
            noIMSS:element.NumeroIMSS||"",
            isMoreData:false,
            direccion:"",
            telefonoEmergencia:"",
            contactoEmergencia:"",
            rfc:element.RFC||"",
            internacional: false,
            estado:element.estado||"",
            estado_short:element.estado_short||"",
            localidad:element.localidad||"",
            localidadSat:element.localidadSat||"",
            municipio:element.municipio||"",
            municipioSat:element.municipioSat||"",
            calle:element.calle||"",
            numero:element.numero||"",
            colonia:element.colonia||"",
            colonias:[],
            coloniaSat:element.coloniaSat||"",
            cp:element.cp||"",
            uid:"xxx",
        };

        return obj;

    })
  

    console.log("unidades user", fixedunidades);
    //    return;

    // Get a new write batch
    var db = firebase.firestore();
    const batch = db.batch();

    // Commit the batch

    fixedunidades.forEach((doc) => {
        var docRef = db.collection("operadores_user").doc(); //automatically generate unique id
        batch.set(docRef, doc);
    });

    /*await db.collection('viajes').get().then(resp => {
       resp.docs.forEach((doc) => {
            const docRef = firebase.firestore().collection("viajes").doc(doc.id)
            batch.update(docRef, {isViajeCargado:true});
        })
    });*/
    await batch.commit();
}
export const getOperadorById = (id) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").doc(id).get();
}

export const getOperadores = (uid) => {
    var db = firebase.firestore();
    return db.collection("operadores_user").where("uid", "==", uid).get();
}

export const addOperador = (data) => {
    let dataaux = { ...data, createAt: new Date() };
    var db = firebase.firestore();
    return db.collection('operadores_user').add(dataaux);
}

export const updateOperador = (id, data) => {
    let dataaux;
    dataaux = { ...data, udpateAt: new Date()};
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update(dataaux);
}

export const deleteOperador = (id) => {
    var db = firebase.firestore();
    return db.collection('operadores_user').doc(id).update({uid:"deleted"});
}
