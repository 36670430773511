import React from "react";
import moment from 'moment';
import PencilIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import ReactExport from "react-export-excel";
import RoomIcon from '@material-ui/icons/Room';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// The avaiable renderer-related props are:
// - row (row index)
// - col (column index)
// - prop (column property name)
// - TD (the HTML cell element)
// - cellProperties (the cellProperties object for the edited cell)

export const MoneyRender = (props) => {

    const { value } = props;

    if (value) {
        let num = '$' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        return (
            <span align="center" className="currencyrecord">{num}</span>

        );
    }
    return (
        <>
            <span></span>
        </>
    );
};
export const NameRender = (props) => {

    const { value } = props;
    if (value) {
        let nombbase = value.split(' (R)');
        let isR = value.includes(' (R)');
        return (
            <>
                <span className="normalrecordleft">{nombbase[0]}
                    {
                        isR ? <span style={{ fontWeight: 'bold', fontSize: '14px' }}> &nbsp;(R)</span> : <></>
                    }
                </span>

            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const DateRender = (props) => {

    const { value } = props;
    if (value) {
        if (value.toDate) {
            return (
                <span align="center" className="normalrecord">{moment(value.toDate()).format("DD/MM/YYYY")}</span>

            );
        } else {
            <>
                <span>-</span>
            </>
        }


    }
    return (
        <>
            <span>-</span>
        </>
    );
};
export const ActionRender = (props) => {

    const { value } = props;
    const { handleEditFromId } = props;
    const { handleDeleteFromId } = props;
    const { handleOpenReport } = props;
    const { handleDownloadExcel } = props;
    const { handleOpenReportP } = props;
    if (value) {
        let dataset = [];
        let adicionales = [];
        let cargos = [];
        let fijos = [];

        if (value.conceptos) {
            value.conceptos.forEach(element => {
                dataset.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: element.descripcion, monto: parseFloat(element.monto) });
            });
        }
        if (value.salariobaseFloat) {
            fijos.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: "Salario Base", monto: value.salariobaseFloat });
            fijos.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: "Salario Diario", monto: value.salariodiarioFloat });
            fijos.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: "Compensación", monto: value.compensacionFloat });
            fijos.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: "Sueldo IMSS", monto: value.sueldoImssFloat });
        }
        if (value.adicionales) {
            value.adicionales.forEach(element => {
                adicionales.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: element.descripcion, monto: parseFloat(element.monto) });
            });
        }
        if (value.cargos) {
            value.cargos.forEach(element => {
                cargos.push({ operador: value.operadorName, inicio: value.inicio, fin: value.fin, descripcion: element.descripcion, monto: -1 * parseFloat(element.monto) });
            });
        }



        let todosJuntos = [...dataset];

        if (fijos.length > 0) {
            todosJuntos = [...todosJuntos, { operado: "", inicio: "", fin: "", descripcion: "", monto: "" }, ...fijos];
        }
        if (adicionales.length > 0) {
            todosJuntos = [...todosJuntos, { operado: "", inicio: "", fin: "", descripcion: "", monto: "" }, ...adicionales];
        }
        if (cargos.length > 0) {
            todosJuntos = [...todosJuntos, { operado: "", inicio: "", fin: "", descripcion: "", monto: "" }, ...cargos];
        }

        return (
            <>
                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleEditFromId(value.id)} aria-label="editar">
                    <PencilIcon />
                </IconButton>
                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleOpenReport(value.id)} aria-label="editar">
                    <DescriptionIcon />
                </IconButton>
                {
                    value.requiereReporteP == true && <IconButton size="small" style={{
                        color: "#3ccc45",

                    }} onClick={() => handleOpenReportP(value.id)} aria-label="editar">
                        <DescriptionIcon />
                    </IconButton>
                }

                <ExcelFile element={<IconButton size="small" style={{
                    color: "#2fa2fa",

                }} aria-label="editar">
                    <i className="fa fa-file-excel-o" style={{ color: "#2fa2fa" }}></i>
                </IconButton>}>
                    <ExcelSheet data={todosJuntos} name="Nomina Operador">
                        <ExcelColumn label="Operador" value="operador" />
                        <ExcelColumn label="Desde" value="inicio" />
                        <ExcelColumn label="Hasta" value="fin" />
                        <ExcelColumn label="Descripcion" value="descripcion" />
                        <ExcelColumn label="Viaje" value="nviaje" />
                        <ExcelColumn label="CP" value="ncarta" />
                        <ExcelColumn label="Monto" value="monto" />
                    </ExcelSheet>

                </ExcelFile>


                <IconButton size="small" style={{
                    color: "#2fa2fa",

                }} onClick={() => handleDeleteFromId(value.id)} aria-label="borrar">
                    <DeleteForeverIcon />
                </IconButton>

            </>
        );
    }
    return (
        <>
            <span>-</span>
        </>
    );
};