import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { actualizaEstatosPagoLiquidacion, getAllViajesByOperadorDateFilter, getAllViajesByOperador2DateFilter } from '../../../controllers/viajes';
import { addNomina, updateNomina } from '../../../controllers/nomina';
import { getUserByUid } from '../../../controllers/usuarios';
import { getOperadores } from '../../../controllers/operadores';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Remove';



const ModalCreateEdit = (props) => {
    //constantes
    const CATALOGOS_TIPO_PAGO = ['Manual', 'Por Porcentaje', 'Por Kilómetro', 'Otro'];
    const CATALOGOS_TIPO_LICENCIA = ['A', 'B', 'C', 'D', 'E', 'F'];
    const CATALOGO_STATUS = ['Por Pagar', 'Pagado'];
    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);

    const [user_viaje, setUserViaje] = useState(null);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [precioDolar, setPrecioDolar] = useState(0.0);
    //estados propios del formulario
    const [operadores, setOperadores] = useState([]);
    const [operador, setOperador] = useState("");
    const [operadorName, setOperadorName] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [firstSteap, setFirstSteap] = useState(true);
    const [viajes, setViajes] = useState([]);
    const [adicionales, setAdicionales] = useState([]);
    const [conceptos, setConceptos] = useState([]);
    const [cargos, setCargos] = useState([]);
    const [totalConceptos, setTotalConceptos] = useState(0.0);
    const [totalCargos, setTotalCargos] = useState(0.0);
    const [totalAdicionales, setTotalAdicionales] = useState(0.0);
    const [totalfinal, setTotalFinal] = useState(0.0);
    const [salariobase, setSalarioBase] = useState("$0.0");
    const [salariobaseFloat, setSalarioBaseFloat] = useState(0.0);
    const [salariodiario, setSalarioDiario] = useState("$0.0");
    const [salariodiarioFloat, setSalarioDiarioFloat] = useState(0.0);
    const [compensacion, setCompensacion] = useState("$0.0");
    const [compensacionFloat, setCompensacionFloat] = useState(0.0);
    const [sueldoImss, setSueldoImss] = useState("$0.0");
    const [sueldoImssFloat, setSueldoImssFloat] = useState(0.0);
    const [totalfijos, setTotalFijos] = useState(0.0);
    const [status, setStauts] = useState("Por Pagar");
    const [numeroLiquidacion, setNumeroLiquidacion] = useState(1000);

    const [odomentroinicial, setodomentroinicial] = useState(0);
    const [odomentrofinal, setodomentrofinal] = useState(0);
    const [costoxkm, setcostoxkm] = useState(0);
    //uid && uid==="KeMdhwEofxWSIrXh2mylJWra9Jr1"
    const requiereNoDividirComisiones = ['KeMdhwEofxWSIrXh2mylJWra9Jr1', 'RZHc17ViPqQTIJ7VShGfbBRwXBw1'];
    const requerimientosP = ['RZHc17ViPqQTIJ7VShGfbBRwXBw1', 'xxxLOZJRkQRLvbN2mWSjX4MFNr1qvj2'];
    const requiereReporteP = ['LOZJRkQRLvbN2mWSjX4MFNr1qvj2'];

    const onChangeDaterange = (dates) => {
        const [start, end] = dates;
        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setStartDate(start);
        setEndDate(end);
    };


    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);
    var moment = require('moment');
    const parseDataViaje = (doc, esoperador2 = false) => {
        let o = doc.data();
        o.id = doc.id;
        o.esoperador2 = esoperador2;
        o.comisionPagada = o.comisionPagada || false;
        o.key = doc.id;
        o.index = doc.id;
        o.name = o.general.nviaje || "viaje";
        o.num = o.general.nviaje;
        o.cliente = o.general.cliente;
        o.destino = `${o.carta_porte.destinoCiudad},${o.carta_porte.destinoEstadoShort}`;
        o.origen = `${o.carta_porte.origenCiudad},${o.carta_porte.origenEstadoShort}`;
        o.destinolugar = `${o.datos_viaje.origen}`;
        o.origenlugar = `${o.datos_viaje.destino}`;
        //element.general.nviaje} {element.general.ncarta} {element.datos_viaje.ruta} {element.toperadorSelected ? element.toperadorSelected.tarifa : ""}
        o.nviaje = o.general.nviaje;
        if (requerimientosP.find(fff => fff === o.uid)) {
            o.name = o.nviaje = o.detalles.unidad + ", " || "";
        }


        o.ncarta = o.general.ncarta;
        o.ruta = o.datos_viaje.ruta;
        if (requerimientosP.find(fff => fff === o.uid)) {
            o.ruta = `${o.datos_viaje.origen} - ${o.datos_viaje.destino}`;
        }


        o.fecha = '';
        if (o.datos_viaje.fechaOrigen) {
            let datefecha = o.datos_viaje.fechaOrigen.toDate();
            o.fecha = moment(datefecha).format('DD/MM/YYYY')
        }

        delete o.general;
        delete o.datos_viaje;
        //delete o.detalles;
        delete o.carta_porte;
        delete o.cpfacuturado;
        delete o.facuturado;
        delete o.mercancias;
        delete o.ttarifarioSelected;
        return o;
    }
    const searchViajes = async (operador, startDate, endDate, edicion = false, datacocneptos = null, dataviajes = null) => {
        console.log("buscando", operador, startDate, endDate);
        let c = []; //no pagados
        let p = [];//pagados

        await getAllViajesByOperadorDateFilter(operador, startDate, endDate).then(snapshot => {
            console.log("encontrados", snapshot.docs.length);
            snapshot.forEach(doc => {
                let o = parseDataViaje(doc);
                if (o.status !== "Cancelado") {
                    o.comisionPagada === true ? p.push(o) : c.push(o);
                } else {
                    console.log("cancelado");
                }

            });
        }).catch(err => { console.log(err) });
        await getAllViajesByOperador2DateFilter(operador, startDate, endDate).then(snapshot => {
            console.log("encontrados op2", snapshot.docs.length);
            snapshot.forEach(doc => {
                let o = parseDataViaje(doc, true);
                if (o.status !== "Cancelado")
                    o.comisionPagada === true ? p.push(o) : c.push(o);
            });
        }).catch(err => { console.log(err) });

        console.log("edicion", edicion);
        console.log("data c", c);
        console.log("data p", p);
        if (edicion == false) {
            console.log("edicion es false");

            if (requerimientosP.find(fff => fff === uid)) {
                c.forEach(ccc => {
                    p.push(ccc);
                })
            } else {
                setViajes(c);
            }

            if (p.length > 0) {

                pastToNominaV(p);

            }
        } else {
            //buscamos si hay nuevos
            if (datacocneptos && dataviajes) {
                console.log("buscando si hay nuevos", datacocneptos, c);
                let nuevosViajes = [];
                c.forEach((cc, ii) => {
                    let buscador = dataviajes.find(ed => ed.id === cc.id);
                    if (ii === 0) {
                        console.log("buscador1", buscador);
                    }
                    if (!buscador) {
                        buscador = datacocneptos.find(ed => ed.viajeobj.id === cc.id);
                        if (ii === 0) {
                            console.log("buscador2", buscador);
                        }
                        if (!buscador)
                            nuevosViajes.push(cc);
                    }
                });
                console.log("nuevo viajes encontrados", nuevosViajes);
                if (nuevosViajes.length > 0) {
                    setViajes([...nuevosViajes]);
                }
                //console.log("bscando si nay nuevo pagados", datacocneptos, p);

            }
        }


    }
    useEffect(() => {
        if (operador && operador !== "" && startDate && endDate) {
            // console.log("buscar", { operador, startDate, endDate });
            setFirstSteap(false);
            if (!isEdit)
                searchViajes(operador, startDate, endDate);
        }

        if (operador && operador !== "") {
            let findoperador = operadores.find(element => element.id === operador);
            if (findoperador) {
                setOperadorName(findoperador.nombre + " " + findoperador.apellidos);
                if (findoperador.sueldoBase && findoperador.sueldoBaseFloat) {
                    setSalarioBase(findoperador.sueldoBase);
                    setSalarioBaseFloat(findoperador.sueldoBaseFloat);
                }
            }
        }


    }, [operador, startDate, endDate])

    useEffect(() => {
        if (uid) {
            getUserByUid(uid).then(snapshot => {
                let obuser = null;
                snapshot.forEach(document => {
                    obuser = { ...document.data(), id: document.id }
                    if (!obuser.segmento_negocio) {
                        obuser.segmento_negocio = [];
                    }

                    if (obuser.dolarprice) {
                        setPrecioDolar(obuser.dolarprice);
                    }
                });
                setUsuarioObj(obuser);
            }).catch(err => { console.log(err) });
        }
    }, [uid])

    const initValues = async () => {

        if (isEdit && recordedit != null) {
            setOperadorName(recordedit.operadorName || "");
            setViajes(recordedit.viajes || []);
            setConceptos(recordedit.conceptos || []);
            setCargos(recordedit.cargos || []);
            setAdicionales(recordedit.adicionales || []);
            setOperador(recordedit.operador || "");
            setTotalConceptos(recordedit.totalConceptos || 0.0);
            setTotalCargos(recordedit.totalCargos || 0.0);
            setStartDate(recordedit.startDate.toDate() || null);
            setEndDate(recordedit.endDate.toDate() || null);
            setodomentroinicial(recordedit.odomentroinicial || 0);
            setodomentrofinal(recordedit.odomentrofinal || 0);
            setcostoxkm(recordedit.costoxkm || 0);
            setFirstSteap(false);

            if (uid && uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1") {
                setNumeroLiquidacion(recordedit.numeroLiquidacion || 0);
            }

            setSalarioBase(recordedit.salariobase || "$0.0");
            setSalarioBaseFloat(recordedit.salariobaseFloat || 0.0);
            setSalarioDiario(recordedit.salariodiario || "$0.0");
            setSalarioDiarioFloat(recordedit.salariodiarioFloat || 0.0);
            setCompensacion(recordedit.compensacion || "$0.0");
            setCompensacionFloat(recordedit.compensacionFloat || 0.0);
            setSueldoImss(recordedit.sueldoImss || "$0.0");
            setSueldoImssFloat(recordedit.sueldoImssFloat || 0.0);
            setStauts(recordedit.status || "Por Pagar");


            //buscar viajes nuevos
            if (recordedit.operador) {
                searchViajes(recordedit.operador, recordedit.startDate.toDate(), recordedit.endDate.toDate(), true, recordedit.conceptos || [], recordedit.viajes || []);
            }

        } else {
            setOperadorName("");
            setAdicionales([]);
            setViajes([]);
            setConceptos([]);
            uid == "HptPbvVxYYUjUzp7Nt3TtmyAt0x1" ? setCargos([{ monto: 0, descripcion: `IMSS`, isExtra: true }, { monto: 0, descripcion: `ISR`, isExtra: true }]) : setCargos([]);
            setTotalConceptos(0.0);
            setTotalCargos(0.0);
            setStartDate(null);
            setEndDate(null);
            setFirstSteap(true);

            setSalarioBase("$0.0");
            setSalarioBaseFloat(0.0);
            setSalarioDiario("$0.0");
            setSalarioDiarioFloat(0.0);
            setCompensacion("$0.0");
            setCompensacionFloat(0.0);
            setSueldoImss("$0.0");
            setSueldoImssFloat(0.0);
            setStauts("Por Pagar");
            setodomentroinicial(0);
            setodomentrofinal(0);

            //todo buscar ultumo costo xkm

        }



        await getOperadores(uid).then(snapshot => {
            let aux = [{ id: "", nombre: "", apellidos: "" }];
            snapshot.forEach(doc => {
                let o = doc.data();
                o.id = doc.id;
                aux.push(o);
            });
            aux.sort((a, b) => {
                if (a.nombre + " " + a.apellidos > b.nombre + " " + b.apellidos) {
                    return 1;
                }
                if (a.nombre + " " + a.apellidos < b.nombre + " " + b.apellidos) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
            setOperadores(aux);
            if (isEdit && recordedit != null) {
                if (operador === "" && aux.length > 0) {
                    setOperador(recordedit.operador || "");
                }
            } else {
                if (operador === "" && aux.length > 0) {
                    setOperador(aux[0].id);
                }
            }

        }).catch(err => { console.log(err) });




    }



    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);

        if (props.isOpen) {
            initValues();
        }
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);



        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            // initValues();
        }

    }, [recordedit]);


    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);

    const handleSumbit = async (e) => {
        e.preventDefault();
        // console.log("submiting");
        if (!endDate || !startDate) {
            Swal.fire("Invalido", "Debes especificar el rango de fechas", 'error');
            return;
        }
        //setIsOpen(false);

        let obj = {
            totalConceptos,
            totalCargos,
            totalAdicionales,
            totalfijos,
            total: parseFloat(parseFloat(totalAdicionales) + parseFloat(totalConceptos) - parseFloat(totalCargos)).toFixed(2),
            viajes,
            fecha: new Date(),
            conceptos,
            adicionales,
            cargos,
            operador,
            operadorName,
            startDate,
            endDate,
            salariobase,
            salariobaseFloat,
            salariodiario,
            salariodiarioFloat,
            compensacion,
            compensacionFloat,
            sueldoImss,
            sueldoImssFloat,
            status,
            odomentroinicial,
            odomentrofinal,
            costoxkm,
            uid,
        }

        if (uid && uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1") {
            obj.numeroLiquidacion = numeroLiquidacion;
        }


        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }


        //viajes tiene no pagados
        //conceptos tiene pagados
        let idsPagados = [];
        let idsNoPagados = [];
        obj.conceptos.forEach(c => {
            if (c.viajeobj && c.viajeobj.id) {
                idsPagados.push(c.viajeobj.id);
            }
        });

        obj.viajes.forEach(c => {
            if (c.id) {
                idsNoPagados.push(c.id);
            }
        });


        //validaciones de formulario
        console.log(obj);


        await actualizaEstatosPagoLiquidacion(idsPagados, idsNoPagados).then(resp => { console.log("finish update") }).catch(err => console.log(err));
        console.log(idsPagados, idsNoPagados);
        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateNomina(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'La nómina se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar la nómina, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addNomina(obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'La nomina se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al registrar nomina, comprueba tu conexión ", 'error');
            })
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const calculateTotales = () => {

        if (conceptos && conceptos.length > 0) {
            let sumatoria = 0.0;
            conceptos.forEach(element => {
                if (element.monto) {
                    sumatoria = parseFloat(parseFloat(sumatoria) + parseFloat(element.monto));
                }
            });
            console.log("calculando totales", sumatoria);
            setTotalConceptos(parseFloat(sumatoria).toFixed(2));
        } else {
            setTotalConceptos(0.0);
        }
    }
    const calculateTotalesCargos = () => {

        if (cargos && cargos.length > 0) {
            let sumatoria = 0.0;
            cargos.forEach(element => {
                if (element.monto) {
                    sumatoria = parseFloat(parseFloat(sumatoria) + parseFloat(element.monto));
                }
            });
            setTotalCargos(parseFloat(sumatoria).toFixed(2));
        } else {
            setTotalCargos(0.0);
        }
    }
    const calculaTotalAdicionales = () => {
        if (adicionales && adicionales.length > 0) {
            let sumatoria = 0.0;
            adicionales.forEach(element => {
                if (element.monto) {
                    sumatoria = parseFloat(parseFloat(sumatoria) + parseFloat(element.monto));
                }
            });
            setTotalAdicionales(parseFloat(sumatoria).toFixed(2));
        } else {
            setTotalAdicionales(0.0);
        }
    }

    useEffect(() => {
        calculateTotales();
    }, [conceptos]);
    useEffect(() => {
        calculateTotalesCargos();
    }, [cargos]);
    useEffect(() => {
        calculaTotalAdicionales();
    }, [adicionales]);

    useEffect(() => {
        let nuevototalfijos = salariodiarioFloat + salariodiarioFloat + compensacionFloat + sueldoImssFloat;
        setTotalFijos(parseFloat(nuevototalfijos).toFixed(2));
    }, [salariobaseFloat, salariodiarioFloat, compensacionFloat, sueldoImssFloat]);

    const pastToNomina = (element) => {

        let newArr = [...viajes];
        let foundIndex = newArr.findIndex(x => x.id === element.id);

        if (foundIndex >= 0) {
            let element = viajes[foundIndex];

            if (element && element.lineas_captura && element.lineas_captura.length > 0) {
                element.lineas_captura.forEach(linea => {
                    if (linea.tipo === "SUELDOS DE OPERADOR") {
                        let newadicional = [...adicionales];
                        newadicional.push({ monto: linea.montoFloat, descripcion: `${linea.descripcion} ${element.nviaje} ${element.ncarta}` });
                        setAdicionales(newadicional)
                    } else if (linea.tipo === "DEDUCCIÓN/DOTACIÓN OPERADOR") {
                        let newCargos = [...cargos];
                        newCargos.push({ monto: linea.montoFloat, descripcion: `${linea.descripcion} ${element.nviaje} ${element.ncarta}` });
                        setCargos(newCargos);
                    }
                });
            }

            let newconceptos = [...conceptos];

            let montodecomision = element.toperadorSelected ? element.toperadorSelected.tarifaFloat : 0.0;

            if (element.esoperador2 === true) {
                montodecomision = element.toperadorSelected2 ? element.toperadorSelected2.tarifaFloat : 0.0;
            }
            //dos operadores dividir
            if (element && element.detalles && element.detalles.operador1Id && element.detalles.operador1Id !== "" && element.detalles.operador2Id && element.detalles.operador2Id !== "" && montodecomision > 0) {

                if (uid && requiereNoDividirComisiones.find(fnd => fnd === uid)) {
                    //no dividimos
                } else {
                    //montodecomision = parseFloat(parseFloat(montodecomision / 2).toFixed(2));
                }


            }

            newconceptos.push({ viajeobj: element, monto: montodecomision, descripcion: ` ${element.nviaje} ${element.ncarta} ${element.fecha} ${element.ruta} ${element.esoperador2 ? element.toperadorSelected2 ? element.toperadorSelected2.tarifa : "" : element.toperadorSelected ? element.toperadorSelected.tarifa : ""} Cliente:${element.cliente || ""} ` });
            setConceptos(newconceptos);
            newArr.splice(foundIndex, 1);
            setViajes(newArr);
        }
    }

    const pastToNominaV = (v) => {

        console.log("passing to nomnina", v);
        let newArr = [...v];
        let newconceptos = [...conceptos];
        v.forEach((element) => {
            if (element && element.lineas_captura && element.lineas_captura.length > 0) {
                element.lineas_captura.forEach(linea => {
                    if (linea.tipo === "SUELDOS DE OPERADOR") {
                        let newadicional = [...adicionales];
                        newadicional.push({ monto: linea.montoFloat, descripcion: `${linea.descripcion} ${element.nviaje} ${element.ncarta}` });
                        setAdicionales(newadicional)
                    } else if (linea.tipo === "DEDUCCIÓN/DOTACIÓN OPERADOR") {
                        let newCargos = [...cargos];
                        newCargos.push({ monto: linea.montoFloat, descripcion: `${linea.descripcion} ${element.nviaje} ${element.ncarta}` });
                        setCargos(newCargos);
                    }
                });
            }
            let montodecomision = element.toperadorSelected ? element.toperadorSelected.tarifaFloat : 0.0;
            //dos operadores dividir
            if (element && element.detalles && element.detalles.operador1Id && element.detalles.operador1Id !== "" && element.detalles.operador2Id && element.detalles.operador2Id !== "" && montodecomision > 0) {

                if (uid && requiereNoDividirComisiones.find(fnd => fnd === uid)) {
                    //no dividimos
                } else {
                    montodecomision = parseFloat(parseFloat(montodecomision / 2).toFixed(2));
                }


            }

            newconceptos.push({ viajeobj: element, monto: montodecomision, descripcion: ` ${element.nviaje} ${element.ncarta} ${element.fecha} ${element.ruta} ${element.esoperador2 ? element.toperadorSelected2 ? element.toperadorSelected2.tarifa : "" : element.toperadorSelected ? element.toperadorSelected.tarifa : ""} Cliente:${element.cliente || ""} ` });



        });
        setConceptos(newconceptos);
    }

    const addConcepto = () => {
        let lcnew = {
            descripcion: "",
            monto: 0.0,
            isExtra: true
        }
        setConceptos([...conceptos, lcnew]);
    }

    const addCargo = () => {
        let lcnew = {
            descripcion: "",
            monto: 0.0,
            isExtra: true
        }
        setCargos([...cargos, lcnew]);
    }
    const addAdicional = () => {
        let lcnew = {
            descripcion: "",
            monto: 0.0,
            isExtra: true
        }
        setAdicionales([...adicionales, lcnew]);
    }

    const changeDescripcion = (foundIndex, value) => {
        //
        let newArr = [...conceptos];
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = value;
            setConceptos(newArr);
        }


    }
    const changeDescripcionAdicional = (foundIndex, value) => {
        //
        let newArr = [...adicionales];
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = value;
            setAdicionales(newArr);
        }


    }
    const changeMonto = (foundIndex, masketValue, value) => {
        //
        let newArr = [...conceptos];
        if (foundIndex >= 0) {
            newArr[foundIndex].monto = value;
            setConceptos(newArr);
        }
    }
    const changeMontoAdicional = (foundIndex, masketValue, value) => {
        //
        let newArr = [...adicionales];
        if (foundIndex >= 0) {
            newArr[foundIndex].monto = value;
            setAdicionales(newArr);
        }
    }
    const changeDescripcionCargo = (foundIndex, value) => {
        //
        let newArr = [...cargos];
        if (foundIndex >= 0) {
            newArr[foundIndex].descripcion = value;
            setCargos(newArr);
        }


    }
    const changeMontoCargo = (foundIndex, masketValue, value) => {
        //
        let newArr = [...cargos];
        if (foundIndex >= 0) {
            newArr[foundIndex].monto = value;
            setCargos(newArr);
        }
    }
    const removeConcepto = (element, index) => {
        let newArr = [...conceptos];
        //let foundIndex = newArr.findIndex(x => x.i === element.i);
        if (conceptos[index] && conceptos[index].viajeobj) {
            let v = [...viajes];
            v.push(conceptos[index].viajeobj);
            setViajes(v);
        }

        if (index >= 0) {
            newArr.splice(index, 1);
            setConceptos(newArr);
        }
    }
    const removeAdicional = (element, index) => {
        let newArr = [...adicionales];


        if (index >= 0) {
            newArr.splice(index, 1);
            setAdicionales(newArr);
        }
    }
    const removeCargos = (element, index) => {
        let newArr = [...cargos];
        //let foundIndex = newArr.findIndex(x => x.i === element.i);

        if (index >= 0) {
            newArr.splice(index, 1);
            setCargos(newArr);
        }
    }

    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Nómina" : `Editar Nómina`}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>

                    <table className="tablenostriped" style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>Operador</label> </td>
                                <td className="modalform_field">
                                    <select className="form-control" name='propiedad' onChange={(e) => setOperador(e.target.value)} value={operador} required >
                                        {
                                            operadores.map((item, index) => {
                                                return <option key={index} value={item.id}>{item.nombre + " " + item.apellidos}</option>
                                            })
                                        }
                                    </select>
                                </td>
                                <td className="" style={{ width: '200px', margin: 0 }}>
                                    <div className="row">
                                        <div className="col-8">
                                            <label>Odomentro inicial</label>
                                            <input placeholder="Descripcion" type="number" value={odomentroinicial} onChange={e => setodomentroinicial(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-8">
                                            <label>Odomentro final</label>
                                            <input placeholder="Descripcion" type="number" value={odomentrofinal} onChange={e => setodomentrofinal(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-8">
                                            <label>Costo xKM</label>
                                            <input placeholder="Descripcion" type="number" value={costoxkm} onChange={e => setcostoxkm(parseFloat(e.target.value))} className="form-control" />
                                        </div>
                                    </div>
                                </td>
                                <td className="modalform_label">  <label> <span className="red">*</span>Rango Fechas</label> </td>
                                <td className="modalform_field">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onChangeDaterange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        inline
                                    />
                                </td>
                            </tr>
                            {!firstSteap && <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>Estatus</label> </td>
                                <td className="modalform_field">
                                    <select className="form-control" name='propiedad' onChange={(e) => setStauts(e.target.value)} value={status} required >
                                        {
                                            CATALOGO_STATUS.map((item, index) => {
                                                return <option key={`stastus${index}`} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>}
                            {uid && uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1" && <tr>
                                <td className="modalform_label">  <label> <span className="red">*</span>No. Liquidación</label> </td>
                                <td className="modalform_field">
                                    <input type='number' className='form-control' value={numeroLiquidacion} onChange={e => setNumeroLiquidacion(parseInt(e.target.value))}></input>
                                </td>
                            </tr>}
                        </tbody>
                    </table>

                    <br />
                    {!firstSteap && <h2>Viajes del operador</h2>}
                    <div className="row" key={`listaviajes`}>
                        <ul className="col-12 list-group">
                            {viajes.map((element, index) => {
                                return <li key={`itemv${index}`} className="list-group-item">
                                    {element.nviaje} {element.ncarta} {element.fecha} {element.ruta} {element.esoperador2 ? element.toperadorSelected2 ? element.toperadorSelected2.tarifa : "" : element.toperadorSelected ? element.toperadorSelected.tarifa : ""} Cliente:{element.cliente || ""}
                                    <IconButton onClick={() => pastToNomina(element)} variant="contained" size="small" color="primary" aria-label="add">
                                        <AddIcon />
                                    </IconButton>
                                </li>
                            })}
                        </ul>

                    </div>

                    <br />

                    {!firstSteap && <div><h2>Pagos a operador <IconButton onClick={() => addConcepto()} variant="contained" size="small" color="primary" aria-label="add">
                        <AddIcon />
                    </IconButton></h2>
                    </div>}
                    <div className="row">
                        <ul className="col-12 list-group">
                            {conceptos.map((element, index) => {

                                return element.isExtra ?
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-8">
                                                <input placeholder="Descripcion" type="text" value={conceptos[index].descripcion} onChange={e => changeDescripcion(index, e.target.value)} className="form-control" />
                                            </div>
                                            <div className="col-3">
                                                <CurrencyInput className="form-control" value={conceptos[index].monto} onChangeEvent={(event, maskedvalue, floatvalue) => changeMonto(index, maskedvalue, floatvalue)} prefix="$" />
                                            </div>
                                            <div className="col-1">
                                                <IconButton size="small" className="btn-remove-factura" onClick={() => removeConcepto(element, index)} aria-label="borrar">
                                                    <CancelIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </li> :
                                    <li className="list-group-item"> <div className="row">
                                        <div className="col-8">
                                            <input disabled placeholder="Descripcion" type="text" value={conceptos[index].descripcion} onChange={e => changeDescripcion(index, e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-3">
                                            <CurrencyInput disabled className="form-control" value={conceptos[index].monto} onChangeEvent={(event, maskedvalue, floatvalue) => changeMonto(index, maskedvalue, floatvalue)} prefix="$" />
                                        </div>
                                        <div className="col-1">
                                            <IconButton size="small" className="btn-remove-factura" onClick={() => removeConcepto(element, index)} aria-label="borrar">
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    </div></li>
                            })}
                        </ul>

                    </div>
                    <br />
                    {!firstSteap && <div><h2>Información fijos </h2></div>}
                    {!firstSteap && <div className="row">
                        <ul className="col-12 list-group">
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-8">
                                        <input placeholder="Salario Semanal" disabled type="text" value={"Salario base"} onChange={e => console.log(e)} className="form-control" />
                                    </div>
                                    <div className="col-3">
                                        <CurrencyInput className="form-control" value={salariobase} onChangeEvent={(event, maskedvalue, floatvalue) => {
                                            setSalarioBase(maskedvalue);
                                            setSalarioBaseFloat(floatvalue);
                                        }} prefix="$" />
                                    </div>

                                </div>
                            </li>

                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-8">
                                        <input placeholder="Compensación" disabled type="text" value={"Compensación"} onChange={e => console.log(e)} className="form-control" />
                                    </div>
                                    <div className="col-3">
                                        <CurrencyInput className="form-control" value={compensacion} onChangeEvent={(event, maskedvalue, floatvalue) => {
                                            setCompensacion(maskedvalue);
                                            setCompensacionFloat(floatvalue);
                                        }} prefix="$" />
                                    </div>

                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-8">
                                        <input placeholder="Sueldo IMSS" disabled type="text" value={"Sueldo IMSS"} onChange={e => console.log(e)} className="form-control" />
                                    </div>
                                    <div className="col-3">
                                        <CurrencyInput className="form-control" value={sueldoImss} onChangeEvent={(event, maskedvalue, floatvalue) => {
                                            setSueldoImss(maskedvalue);
                                            setSueldoImssFloat(floatvalue);
                                        }} prefix="$" />
                                    </div>

                                </div>
                            </li>
                        </ul>
                    </div>}
                    <br />
                    {!firstSteap && <h2>Adicionales<IconButton onClick={() => addAdicional()} variant="contained" size="small" color="primary" aria-label="add">
                        <AddIcon />
                    </IconButton></h2>}
                    {!firstSteap && <div className="row">
                        <ul className="col-12 list-group">
                            {adicionales.map((element, index) => {
                                return element.isExtra ? <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-8">
                                            <input placeholder="Descripcion" type="text" value={adicionales[index].descripcion} onChange={e => changeDescripcionAdicional(index, e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-3">
                                            <CurrencyInput className="form-control" value={adicionales[index].monto} onChangeEvent={(event, maskedvalue, floatvalue) => changeMontoAdicional(index, maskedvalue, floatvalue)} prefix="$" />
                                        </div>
                                        <div className="col-1">
                                            <IconButton size="small" className="btn-remove-factura" onClick={() => removeAdicional(element, index)} aria-label="borrar">
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </li> : <li className="list-group-item"> <div className="row">
                                    <div className="col-8">
                                        <input disabled placeholder="Descripcion" type="text" value={element.descripcion} onChange={e => console.log(index, e.target.value)} className="form-control" />
                                    </div>
                                    <div className="col-3">
                                        <CurrencyInput disabled className="form-control" value={element.monto} onChangeEvent={(event, maskedvalue, floatvalue) => console.log(index, maskedvalue, floatvalue)} prefix="$" />
                                    </div>
                                    <div className="col-1">
                                        <IconButton size="small" className="btn-remove-factura" onClick={() => removeAdicional(element, index)} aria-label="borrar">
                                            <CancelIcon />
                                        </IconButton>
                                    </div>
                                </div></li>
                            })}
                        </ul>

                    </div>}
                    <br />
                    {!firstSteap && <div><h2>Deducciones a operador <IconButton onClick={() => addCargo()} variant="contained" size="small" color="primary" aria-label="add">
                        <AddIcon />
                    </IconButton></h2>
                    </div>}
                    {!firstSteap && <div className="row">
                        <ul className="col-12 list-group">
                            {cargos.map((element, index) => {

                                return <li className="list-group-item">
                                    <div className="row">
                                        <div className="col-8">
                                            <input placeholder="Descripcion" type="text" value={cargos[index].descripcion} onChange={e => changeDescripcionCargo(index, e.target.value)} className="form-control" />
                                        </div>
                                        <div className="col-3">
                                            <CurrencyInput className="form-control" value={cargos[index].monto} onChangeEvent={(event, maskedvalue, floatvalue) => changeMontoCargo(index, maskedvalue, floatvalue)} prefix="$" />
                                        </div>
                                        <div className="col-1">
                                            <IconButton size="small" className="btn-remove-factura" onClick={() => removeCargos(element, index)} aria-label="borrar">
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>

                    </div>
                    }
                    <br />
                    {!firstSteap && <h2>Total</h2>}
                    {!firstSteap && <div className="row">
                        <ul className="col-12 list-group">
                            <li className="list-group-item">Pagos: {totalConceptos}</li>
                            {/*<li className="list-group-item">Fijos: {totalfijos}</li>*/}
                            <li className="list-group-item">Adicionales: {totalAdicionales}</li>
                            <li className="list-group-item">Deducciones: {totalCargos}</li>
                            <li className="list-group-item">Total: {parseFloat(parseFloat(totalAdicionales) + parseFloat(totalConceptos) - parseFloat(totalCargos)).toFixed(2)}</li>
                        </ul>
                    </div>}
                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Cancelar</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>
    </Modal >

}
export default ModalCreateEdit;