import React, { Component, Fragment, useEffect, useState, useRef } from 'react';
import { ListenerPendientes } from '../../controllers/viajes';
import Swal from 'sweetalert2';
import { firebase } from '../../configs/firebase';
import { getUidMaster } from '../../session/session_controller';
import Badge from '@material-ui/core/Badge';

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
const SideBarNew = props => {

    const [isToggle, setIsToggle] = useState(props.isToggle);

    const [accessLevel, setAccessLevel] = useState(1);
    const [cargasnuevas, setCargasNuevas] = useState(0);
    const [denys, setDenys] = useState([]);
    const [allows, setAllows] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') !== '')) {

            switch (localStorage.getItem('marketpointmanager-user')) {
                case 'user':
                    setAccessLevel(1);
                    break;
                case 'gerencia':
                    setAccessLevel(2);
                    break;
                case 'administrador':
                    setAccessLevel(4);
                    break;
                default:
                    setAccessLevel(0);
                    break;
            }
        }

        if (localStorage.getItem('deny')) {
            let auxdeny = localStorage.getItem('deny').split(":");
            setDenys(auxdeny);
        }

        if (localStorage.getItem('allow')) {
            let auxdallow = localStorage.getItem('allow').split(":");
            setAllows(auxdallow);
        }

        const listen = async (uid) => {
            console.log("start listenign")
            ListenerPendientes(uid).onSnapshot(snapshot => {
                if (snapshot && snapshot.docs) {
                    if (snapshot.docs.length > 0) {
                        Toast.fire({
                            icon: 'info',
                            title: 'Nuevo cargamento registrado en el portal de clientes..'
                        })
                    }

                    setCargasNuevas(snapshot.docs.length);
                    // this.setState({ cargasnuevas: snapshot.docs.length });
                }
            })
        }

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                // setUidAltaEdit(userr.uid);
                let uiduser = getUidMaster() ? getUidMaster() :
                    userr.uid;
                listen(uiduser);

            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });
    }, []);
    useEffect(() => {
        setIsToggle(props.isToggle);
    }, [props])

    return <div className={isToggle ? "mainMenu hide" : "mainMenu"}>

        <a href="/dashboard" >
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/dashboard-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Dashboard</p></div>
            </div>
        </a>

        {!denys.find(e => e == "planeacion") && <a href="/planeacion">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/planeacion-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Planeación</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "viajes") && <a href="/viajes">
            <Badge badgeContent={cargasnuevas} color="primary">
                <div className="menuLink">
                    <div className="menuIcon"><img src="/imgs/viajes-icon.svg" alt="" /></div>
                    <div className="menuItem"><p className="fixedP">Viajes</p></div>

                </div>
            </Badge>

        </a>}

        {!denys.find(e => e == "controlviajes") && (allows.find(e => e === "controlviajes")) && <a href="/viajesc">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/rentabilidad-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">ViajesKMS</p></div>
            </div>
        </a>}

        {/*<a href="/cartasporte">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/cartasporte-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Cartas porte</p></div>
            </div>
</a>*/}

        {!denys.find(e => e == "facturas") && <a href="/facturas">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/facturas-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Facturas</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "costos") && <a href="/costos">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/costos-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Costos</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "ingresos") && <a href="/ingresos">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/ingresos-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Ingresos</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "activos") && <a href="/activos">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/activos-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Activos</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "nomina") && <a href="/nomina">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/nomina-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Liquidación</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "costoxkm") && (accessLevel > 1 || allows.find(e => e === "costoxkm")) && <a href="/costoxkm">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/costokm-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Costo x km</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "rentabilidad") && (accessLevel > 1 || allows.find(e => e === "costoxkm")) && <a href="/rentabilidad">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/rentabilidad-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Rentabilidad</p></div>
            </div>
        </a>}

        {!denys.find(e => e == "mantenimiento") && <a href="/mantenimiento">
            <div className="menuLink">
                <div className="menuIcon"><img src="/imgs/build2-icon.svg" alt="" /></div>
                <div className="menuItem"><p className="fixedP">Mantenimiento</p></div>
            </div>
        </a>}



    </div>
}

export default SideBarNew;