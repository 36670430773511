import React, { useEffect, useState } from 'react';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { getNominaById } from '../../../controllers/nomina';
import { getUserByUid } from '../../../controllers/usuarios';
import moment from 'moment';
import { Row, Table } from 'react-bootstrap';
//var QRCode = require('qrcode.react');
// Create styles
const formatoMoneda = (valor) => {
    let fixtostring = "" + valor;
    let fixtofloat = parseFloat(fixtostring);
    let toMoney = '$' + fixtofloat.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    return toMoney;

}
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        marginLeft: 15,
        paddingRight: 15,
        // backgroundColor: '#E4E4E4'
    },
    cabeceraColor: {
        backgroundColor: '#E4E4E4'
    },
    sectionHeader: {
        width: '98%',
        flexDirection: 'row',
    },
    sectionLogo: {
        margin: 0,
        padding: 0,
        width: '20%',
        //border: '1px solid black',
        flexGrow: 1
    },
    sectionUserInfo: {
        width: '50%',
        margin: 10,
        padding: 10,
        flexGrow: 2
    },
    sectionConceptos: {
        width: '98%',
        margin: 10,
        padding: 10,
    },
    sectionConceptosCincuenta: {
        width: '48%',
        margin: 10,
        padding: 10,
    },
    sectionRowConcepto: {
        width: '98%',
        padding: "5px",
        borderBottom: '1px solid black',
        flexDirection: 'row',
    },
    sectionRowConceptoColor: {
        width: '100%',
        padding: "5px",
        borderBottom: '1px solid black',
        flexDirection: 'row',
        backgroundColor: '#E4E4E4'
    },
    sectionRowQuintas: {
        width: '20%',
        padding: "1px",
        //backgroundColor: '#E4E4E4',
        //borderBottom: '1px solid black',
        flexDirection: 'column',
    },
    sectionDescripcionConcepto: {
        width: '80%',
        fontSize: '7px',
    },
    sectionDescripcionConceptoColor: {
        width: '80%',
        fontSize: '7px',
    },
    sectionMontoConcepto: {
        width: '100%',
        fontSize: '7px',
    },

});
const Reporte = props => {

    const data = props.objdata;
    let fechaImpresion = moment(new Date()).format('DD/MM/YYYY HH:mm');
    let fechaInicio = moment(data.startDate.toDate()).format('DD/MM/YYYY');
    let fechaFin = moment(data.endDate.toDate()).format('DD/MM/YYYY');
    let totalConceptos = parseFloat(data.totalConceptos).toFixed(2) || 0.0;
    let totalCargos = parseFloat(data.totalCargos).toFixed(2) || 0.0;
    let totalFijos = parseFloat(data.totalfijos).toFixed(2) || 0.0;

    let totalConceptosstring = parseFloat(data.totalConceptos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalcargosstring = parseFloat(data.totalCargos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalFijosstring = parseFloat(totalFijos).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    let adicionales = [];
    let totalesadicionales = 0.0;
    let totales = (totalConceptos - totalCargos);

    if (!isNaN(totalFijos)) {
        //totales += parseFloat(totalFijos);
    }


    if (data.adicionales) {
        data.adicionales.forEach(element => {
            let montoasFloat = 0.0;
            if (typeof element.monto === 'string' || element.monto instanceof String) {
                montoasFloat = parseFloat(element.monto);
            } else {
                montoasFloat = element.monto;
            }
            adicionales.push(element);
            totalesadicionales += montoasFloat;
            totales += montoasFloat;
        });
    }
    /* data.conceptos.forEach(element => {
         if (element.viajeobj && element.viajeobj.lineas_captura) {
             element.viajeobj.lineas_captura.forEach(linea => {
                 if (linea.tipo === "SUELDOS DE OPERADOR") {
                     totalesadicionales += linea.montoFloat;
                     totales+=linea.montoFloat;
                     adicionales.push({ descripcion: linea.descripcion + `   ${element.viajeobj.general.nviaje}   ${element.viajeobj.general.ncarta}`, monto: linea.montoFloat });
                 }
             });
         }
     });*/

    let totalesadicionalestring = totalesadicionales.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    let totalesstring = totales.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    useEffect(() => {
        // console.log("props", props); https://i.ibb.co/xGTh7HL/LOGO-Transportes-500x362.png
    }, []);

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.sectionHeader}>
                <View style={styles.sectionLogo}>

                    <Image style={{ width: '100px', height: "80px" }} src={data.logourl || "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/2048px-No_image_available.svg.png"}></Image>
                </View>
                <View style={styles.sectionUserInfo}>
                    <Text style={{ fontSize: '10px', marginTop: "5px" }}>Bitácora de Operador: {data.operadorName ? data.operadorName.toUpperCase() : ""}</Text>
                    <Text style={{ fontSize: '10px', marginTop: "5px" }}>De: {fechaInicio} A: {fechaFin}</Text>
                    <Text style={{ fontSize: '10px', marginTop: "5px" }}>Fecha de impresión: {fechaImpresion}</Text>
                </View>


            </View>
            <View style={styles.sectionConceptos}>
                <Text style={styles.cabeceraColor}>Liquidación</Text>
                <View style={{ width: '100%', flexDirection: 'row', }}>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Unidad: ${data.unidadoperador}`}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Odometro Inicial: ${data.odomentroinicial}`}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Odomentro Final: ${data.odomentrofinal}`}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`KMS: ${data.kmstotales}`}</Text>
                    </View>
                </View>
                <View style={{ width: '100%', flexDirection: 'row', }}>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Ingreso x KM: ${data.ingresoxkm || 0}`}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Costo x KM: ${data.costoxkm || 0}`}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {`Utilidad x KM: ${parseFloat(data.ingresoxkm - data.costoxkm).toFixed(2)}`}</Text>
                    </View>

                </View>
            </View>
            <View style={styles.sectionConceptos}>
                <Text style={styles.cabeceraColor}>Viajes</Text>
                <View style={{ width: '100%', backgroundColor: '#E4E4E4', flexDirection: 'row', }}>
                    <View style={{
                        width: '12%',
                        padding: "1px",
                        //backgroundColor: '#E4E4E4',
                        //borderBottom: '1px solid black',
                        flexDirection: 'column',
                    }}>
                        <Text style={styles.sectionDescripcionConcepto}>Fecha</Text>

                    </View>
                    <View style={{
                        width: '28%',
                        padding: "1px",
                        //backgroundColor: '#E4E4E4',
                        //borderBottom: '1px solid black',
                        flexDirection: 'column',
                    }}>
                        <Text style={styles.sectionMontoConcepto}> Ruta</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> CP</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> Ingreso</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> Liquidar</Text>
                    </View>
                </View>
                {data.conceptos.map(element => {
                    let montostring = "";
                    if (typeof element.monto === 'string' || element.monto instanceof String) {
                        montostring = parseFloat(element.monto).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    } else {
                        montostring = element.monto.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                    }

                    return <View style={{ width: '100%', flexDirection: 'row', }}>
                        <View style={{
                            width: '12%',
                            padding: "1px",
                            //backgroundColor: '#E4E4E4',
                            //borderBottom: '1px solid black',
                            flexDirection: 'column',
                        }}>
                            <Text style={styles.sectionDescripcionConcepto}>{element.viajeobj.fecha}</Text>

                        </View>
                        <View style={{
                            width: '28%',
                            padding: "1px",
                            //backgroundColor: '#E4E4E4',
                            //borderBottom: '1px solid black',
                            flexDirection: 'column',
                        }}>
                            <Text style={styles.sectionMontoConcepto}> {`${element.viajeobj.origenlugar ? element.viajeobj.origenlugar.split("-")[0] : ""}/${element.viajeobj.destinolugar ? element.viajeobj.destinolugar.split("-")[0] : ""}`}</Text>
                        </View>
                        <View style={styles.sectionRowQuintas}>
                            <Text style={styles.sectionMontoConcepto}> {`${element.viajeobj.nviaje} ${element.viajeobj.ncarta}`}</Text>
                        </View>
                        <View style={styles.sectionRowQuintas}>
                            <Text style={styles.sectionMontoConcepto}> {`${element.viajeobj.detalles.tarifa || "-"}`}</Text>
                        </View>
                        <View style={styles.sectionRowQuintas}>
                            <Text style={styles.sectionMontoConcepto}> ${element.monto}</Text>
                        </View>
                    </View>
                })}
                <View style={{ width: '100%', flexDirection: 'row', }}>
                    <View style={{
                        width: '12%',
                        padding: "1px",
                        //backgroundColor: '#E4E4E4',
                        //borderBottom: '1px solid black',
                        flexDirection: 'column',
                    }}>
                        <Text style={styles.sectionDescripcionConcepto}>Totales</Text>

                    </View>
                    <View style={{
                        width: '28%',
                        padding: "1px",
                        //backgroundColor: '#E4E4E4',
                        //borderBottom: '1px solid black',
                        flexDirection: 'column',
                    }}>
                        <Text style={styles.sectionMontoConcepto}> {` `}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {` `}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}> {formatoMoneda(data.conceptos.reduce((vanterior, vactual) => {
                            return vanterior + vactual.viajeobj.detalles.tarifaFloat;
                        }, 0))}</Text>
                    </View>
                    <View style={styles.sectionRowQuintas}>
                        <Text style={styles.sectionMontoConcepto}>  {formatoMoneda(data.conceptos.reduce((vanterior, vactual) => {
                            return vanterior + parseFloat(vactual.monto);
                        }, 0))}</Text>
                    </View>
                </View>

            </View>
            <View style={{ width: '100%', flexDirection: 'row', }}>
                <View style={styles.sectionConceptosCincuenta}>
                    <Text style={styles.cabeceraColor}>Gastos de Viaje</Text>
                    <View style={styles.sectionRowConceptoColor}>
                        <Text style={styles.sectionDescripcionConcepto}>Concepto</Text>
                        <Text style={styles.sectionDescripcionConcepto}>CP</Text>
                        <Text style={styles.sectionMontoConcepto}> Importe</Text>
                    </View>
                    {data.gastosviajes.map(element => {
                        return <View style={styles.sectionRowConcepto}>
                            <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                            <Text style={styles.sectionDescripcionConcepto}>{element.cp}</Text>
                            <Text style={styles.sectionMontoConcepto}> {element.monto || "$0.0"}</Text>
                        </View>
                    }
                    )}

                </View>
                <View style={styles.sectionConceptosCincuenta}>
                    <Text style={styles.cabeceraColor}>Costo Fijo</Text>
                    <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>Sueldo Semanal</Text>
                        <Text style={styles.sectionMontoConcepto}> {data.salariobase || "$0.0"}</Text>
                    </View>
                    <View style={styles.sectionRowConcepto}>
                        <Text style={styles.sectionDescripcionConcepto}>Compensaciones</Text>
                        <Text style={styles.sectionMontoConcepto}> {data.compensacion || "$0.0"}</Text>
                    </View>

                </View>
            </View>
            <View style={{ width: '100%', flexDirection: 'row', }}>
                <View style={styles.sectionConceptosCincuenta}>
                    <Text style={styles.cabeceraColor}>Dotación Operador</Text>
                    <View style={styles.sectionRowConceptoColor}>
                        <Text style={styles.sectionDescripcionConcepto}>Concepto</Text>
                        <Text style={styles.sectionDescripcionConcepto}>CP</Text>
                        <Text style={styles.sectionMontoConcepto}> Importe</Text>
                    </View>
                    {data.dotaciones.map(element => {
                        return <View style={styles.sectionRowConcepto}>
                            <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                            <Text style={styles.sectionDescripcionConcepto}>{element.cp}</Text>
                            <Text style={styles.sectionMontoConcepto}> {element.monto || "$0.0"}</Text>
                        </View>
                    }
                    )}

                </View>
                <View style={styles.sectionConceptosCincuenta}>
                    <Text style={styles.cabeceraColor}>Gastos Diesel</Text>
                    <View style={styles.sectionRowConceptoColor}>
                        <Text style={styles.sectionDescripcionConcepto}>Factura</Text>
                        <Text style={styles.sectionDescripcionConcepto}>CP</Text>
                        <Text style={styles.sectionMontoConcepto}> Importe</Text>
                    </View>
                    {data.combustible.map(element => {
                        return <View style={styles.sectionRowConcepto}>
                            <Text style={styles.sectionDescripcionConcepto}>{element.descripcion}</Text>
                            <Text style={styles.sectionDescripcionConcepto}>{element.cp}</Text>
                            <Text style={styles.sectionMontoConcepto}> {element.monto || "$0.0"}</Text>
                        </View>
                    }
                    )}

                </View>
            </View>

        </Page>

    </Document>;
};

const PDFView = props => {
    const params = useParams();
    const [id, setId] = useState(params.id || null);
    const [objData, setObjData] = useState(null);


    const getData = async () => {
        let r = null;
        if (id) {
            // console.log("getting nomina",id);
            await getNominaById(id).then(document => {
                r = document.data();
                // console.log("datos nomina",r);
            }).catch(err => { console.log(err) });

            let gastosviajes = [];
            let dotaciones = [];
            let combustible = [];
            let unidadoperador = "";
            let ingresostotales = 0;
            let kmstotales = 0;
            let ingresoxkm = 0;
            ingresostotales = r.conceptos.reduce((vanterior, vactual) => {
                return vanterior + vactual.viajeobj.detalles.tarifaFloat;
            }, 0);
            if (r.odomentroinicial && r.odomentrofinal) {
                kmstotales = parseFloat(r.odomentrofinal) - parseFloat(r.odomentroinicial);
                if (kmstotales > 0) {
                    ingresoxkm = parseFloat(parseFloat(ingresostotales / kmstotales).toFixed(2));
                }
            }
            r.conceptos.forEach(buscagasto => {
                if (buscagasto.viajeobj) {
                    if (buscagasto.viajeobj.detalles && buscagasto.viajeobj.detalles.unidad) {
                        unidadoperador = buscagasto.viajeobj.detalles.unidad;
                    }
                    buscagasto.viajeobj.lineas_captura.forEach(element => {
                        if (element.tipo === "SUELDOS DE OPERADOR") {
                            gastosviajes.push({
                                descripcion: element.descripcion,
                                monto: element.monto,
                                montoFloat: element.montoFloat,
                                cp: `${buscagasto.viajeobj.nviaje} ${buscagasto.viajeobj.ncarta}`
                            });
                        }
                        if (element.tipo === "DEDUCCIÓN/DOTACIÓN OPERADOR") {
                            dotaciones.push({
                                descripcion: element.descripcion,
                                monto: element.monto,
                                montoFloat: element.montoFloat,
                                cp: `${buscagasto.viajeobj.nviaje} ${buscagasto.viajeobj.ncarta}`
                            });
                        }
                        if (element.tipo === "COMBUSTIBLE") {
                            combustible.push({
                                descripcion: element.descripcion,
                                monto: element.monto,
                                montoFloat: element.montoFloat,
                                cp: `${buscagasto.viajeobj.nviaje} ${buscagasto.viajeobj.ncarta}`
                            });
                        }

                    });
                }
            });

            r.gastosviajes = [...gastosviajes];
            r.dotaciones = [...dotaciones];
            r.combustible = [...combustible];
            r.unidadoperador = unidadoperador;
            r.ingresostotales = ingresostotales;
            r.kmstotales = kmstotales;
            r.ingresoxkm = ingresoxkm;

            if (r.uid) {
                let userObj = null;
                await getUserByUid(r.uid).then(snapshot => {
                    if (snapshot.docs.length > 0) {
                        userObj = snapshot.docs[0].data();
                    }
                }).catch(err => { console.log(err) });
                if (userObj && userObj.logourl) {
                    r.logourl = userObj.logourl;
                }
            }

            let sbase = r.salariobaseFloat || 0.00;
            let scomp = r.compensacionFloat || 0.00;
            let inicial = {
                descripcion: 'Sueldo Semanal + Compensación',
                monto: sbase + scomp
            }
            let adicionales = [inicial, ...r.adicionales];
            r.adicionales = adicionales;
            // data.salariobase
            setObjData(r);
        }

    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (objData) {
            //console.log(objData);
        }
    }, [objData])

    return (
        <div>
            {
                objData &&
                <PDFViewer style={{ width: '100vw', height: '99vh' }}>
                    <Reporte objdata={objData} algo="hola mundo" />
                </PDFViewer>
            }
        </div>);

}

export default PDFView;