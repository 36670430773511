import React, { useState, useEffect, Fragment } from 'react';
import { firebase } from '../../../configs/firebase';
import { deleteIngreso } from '../../../controllers/ingresos';
import { getUserByUid, getAllUsuarios } from '../../../controllers/usuarios';
import LoadingOverlay from 'react-loading-overlay';
import Swal from 'sweetalert2';
import DatePicker from 'react-datepicker';
import { addDays } from "date-fns";
import { HotTable, HotColumn } from '@handsontable/react';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { registerLanguageDictionary } from 'handsontable/i18n';
import esMX from 'handsontable/i18n/languages/es-MX';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { getNominasDateFilter, deleteNomina, getNominasByRange } from '../../../controllers/nomina';
import { ActionRender, DateRender, MoneyRender } from './ActionsRender';
import ModalCreateEdit from '../ModalCreateEdit';
import { DownloadExcel } from './exelreport';
import { getUidMaster } from '../../../session/session_controller';
import IconButton from '@material-ui/core/IconButton';
import ReactExport from "react-export-excel";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
//import Download from './ExcelExport';

const requiereMostrarNumeroLiquidacion = ['KeMdhwEofxWSIrXh2mylJWra9Jr1'];
const requiereReporteP = ['LOZJRkQRLvbN2mWSjX4MFNr1qvj2'];

const CartasPorteContent = (props) => {

    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [userlist, setUserlist] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const [firstDay, setFirstDay] = useState(null);
    const [lastDay, setLastDay] = useState(null);
    const [user, setUser] = useState(null);
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [querysearch, setQuerysearch] = useState('');
    const [ingresos, setIngresos] = useState([]);
    const [ingresosNoFilter, setIngresosNoFilter] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [currenteditrecrod, setCurrentEditRecord] = useState(null);
    const [total, setTotal] = useState(0.0);
    const [totalMask, setTotalMask] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const [datareport, setDataReport] = useState(null);

    const handleCreate = () => {
        setIsEdit(false);
        setCurrentEditRecord({ none: true });
        setIsOpen(true);
    }

    const handleReporte = () => {

    }

    const validateAdmin = async (uid) => {
        await getUserByUid(uid).then(snapshot => {
            let isadmin = false;
            snapshot.forEach(doc => {
                let user = doc.data();
                if (user.type && user.type === "administrador") {
                    isadmin = true;
                }
            });
            if (isadmin) {
                setAdminCurrentUser(uid);
                setIsAdmin(true);
            } else {
                localStorage.setItem('marketpointmanager-user', 'user');
                setIsAdmin(false);
                setUserlist([]);
                // this.setState({ isAdmin: isadmin, userlist: [] });
            }

        }).catch();
    }

    useEffect(() => {
        if (isAdmin) {
            getAllUsuarios().then(snapshot => {
                let index = 1;
                let a = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    index++;
                    a.push(o);
                });
                setUserlist(a);
            }).catch(e => {
                console.log(e);
                //this.setState({isLoading:false,})
            });
        }
    }, [isAdmin]);

    useEffect(() => {
        setTotalMask(total.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
    }, [total]);

    useEffect(() => {


        let fDay = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        let lDay = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);

        firebase.auth().onAuthStateChanged((userr) => {
            if (userr) {
                setUidAltaEdit(userr.uid);
                getUidMaster() ? setUid(getUidMaster()) :
                    setUid(userr.uid);

                setUser(userr);
                // setAdminCurrentUser(userr.uid);

                if (localStorage.getItem('marketpointmanager-user') && localStorage.getItem('marketpointmanager-user') === 'administrador') {
                    // this.validateAdmin(user.uid);
                    //console.log("validando admin");
                    validateAdmin(userr.uid);
                }
            } else {
                // No user is signed in.
                localStorage.removeItem('marketpointmanagerid');
                localStorage.removeItem('marketpointmanager-user');
                window.location.href = '/';
            }
        });

    }, []);


    useEffect(() => {
        if (querysearch.trim() === '') {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                aux.push(element);
            });
            setIngresos(aux);
        } else {
            let aux = [];
            ingresosNoFilter.forEach(element => {
                let query = querysearch.trim().toUpperCase();
                if (element.nombre.toUpperCase().includes(query) ||
                    element.apellidos.toUpperCase().includes(query) ||
                    element.noLicencia.toUpperCase().includes(query) ||
                    element.noIMSS.toUpperCase().includes(query)) {
                    aux.push(element);
                }

            });
            setIngresos(aux.map(element => {
                return { ...element, obj: element };
            }));
        }
    }, [querysearch]);

    useEffect(() => {
        getData(user);
    }, [user, currentDate, adminCurrentUser]);

    useEffect(() => {
        if (!isOpen) {
            setCurrentEditRecord(null);
        }
    }, [isOpen])


    useEffect(() => {
        console.log("total cp", ingresos);
    }, [ingresos])

    const refresh = () => {
        getData(user);
    }

    const getData = async (usertodisplay) => {
        if (user != null) {
            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }
            setIsLoading(true);
            let a = [];
            let index = 0;
            var moment = require('moment'); // require
            console.log("getting", `${firstDay} ${lastDay}`);
            await getNominasDateFilter(usertoconsult.uid, firstDay, lastDay).then(snapshot => {
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.index = doc.id;
                    o.n = index;
                    o.id = doc.id;
                    o.key = doc.id;
                    o.inicio = moment(o.startDate.toDate()).format("DD/MM/YYYY");
                    o.fin = moment(o.endDate.toDate()).format("DD/MM/YYYY");
                    o.estatus = o.status || "Por Pagar";
                    o.numeroLiquidacion = isNaN(o.numeroLiquidacion) ? '' : o.numeroLiquidacion;
                    if(requiereReporteP.find(ff=>ff===o.uid)){
                        o.requiereReporteP=true;
                    }
                    o.obj = { ...o }
                    a.push(o);
                });
                setIngresos(a);
                setIngresosNoFilter(a);
                setIsLoading(false);
            }).catch(err => {
                console.log(err);
                Swal.fire("Error", "Error de al consultar los datos, comprueba tu conexión ", 'error');
                setIsLoading(false);
            });
        } else {
            //console.log("user null");
        }
    }

    const handleDateChanged = (date) => {
        console.log("cambio date", date);
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }
    const handleDateChangedWithRefresh = (date) => {
        let fDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setFirstDay(fDay);
        setLastDay(lDay);
        setCurrentDate(date);
    }

    const findRecord = (id) => {
        let obj = ingresos.find(element => element.id === id);
        return obj;
    }

    const handleOpenReport = id => {
        let obj = ingresos.find(element => element.id === id);
        let fechatime = new Date().getTime();
        if (obj) {
             if (uid && uid === "KeMdhwEofxWSIrXh2mylJWra9Jr1") {
                //cuenta medel
                const win = window.open('/reporttmedel/' + id + "?d=" + fechatime, "_blank");
                win.focus();
            } else {
                const win = window.open('/reportnom/' + id + "?d=" + fechatime, "_blank");
                win.focus();
            }

        }
    }
    const handleOpenReportP = id => {
        let obj = ingresos.find(element => element.id === id);
        let fechatime = new Date().getTime();
        if (obj) {
            const win = window.open('/reportnomp/' + id + "?d=" + fechatime, "_blank");
            win.focus();
        }
    }
    const handleDownloadExcel = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            DownloadExcel(obj);
        }
    }
    const handleEditFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            setIsEdit(true);
            setCurrentEditRecord(obj);

        }
    }
    useEffect(() => {
        if (currenteditrecrod != null) {
            if (currenteditrecrod.none) {
                setIsEdit(false);
            } else {
                setIsEdit(true);
            }
            setIsOpen(true);
        }
    }, [currenteditrecrod])

    const handleDeleteFromId = id => {
        let obj = ingresos.find(element => element.id === id);
        if (obj) {
            Swal.fire({
                title: '¿Esta seguro de eliminar la nomina seleccionada?',
                text: "Una vez eliminada no podrá ser recuperado.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#1c666f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.value) {
                    // alert("borrando");
                    deleteNomina(id).then(() => {
                        refresh();
                        Swal.fire('Éxito', 'Se ha eliminado con éxito.', 'success');
                    }).catch(err => {
                        Swal.fire("Error", "Error de al eliminar la nomina revisa tu conexion", 'error');

                    });
                }

            });
        }
    }
    const onChangeDaterange = (dates) => {
        const [start, end] = dates;
        if (start && end) {
            if (start.getTime() >= end.getTime()) {
                if (start)
                    start.setHours(0, 0, 0);
                if (end)
                    end.setHours(23, 59, 59);
                setStartDate(start);
                setEndDate(end);
                setTimeout(() => {
                    console.log("consultar busqueda por fechas");
                }, 500);
            }
        }

    };
    const onChangeDaterangeTwo = (fechas) => {
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setStartDate(start);
        setEndDate(end);


        // setFechaRangoStart(start);
        // setFechaRangoEnd(end);

    };
    const onChangeDaterangeTwoFilter = (fechas) => {
        var moment = require('moment'); // require
        if (!fechas || !fechas[0] || !fechas[1]) {
            return;
        }

        let start = new Date(fechas[0].getTime());
        let end = new Date(fechas[1].getTime());

        if (start)
            start.setHours(0, 0, 0);
        if (end)
            end.setHours(23, 59, 59);
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            let auxiin = [];
            ingresosNoFilter.forEach(inofilter => {
                let fechainicio = null;
                let fechafin = null;
                if (inofilter.inicio) {
                    fechainicio = moment(inofilter.inicio, 'DD/MM/YYYY').toDate();
                }
                if (inofilter.fin) {
                    fechafin = moment(inofilter.fin, 'DD/MM/YYYY').toDate();
                }
                if (fechainicio && fechafin) {
                    if (fechainicio >= start && fechafin <= end) {
                        console.log("si se cumple",`${fechainicio} ${start}    ${fechafin} ${end}`);
               
                        auxiin.push(inofilter);
                    }
                }

            });
           setIngresos(auxiin);

        }
        // setFechaRangoStart(start);
        // setFechaRangoEnd(end);

    };
    useEffect(() => {

        if (startDate && endDate) {

            var moment = require('moment'); // require
            let aux = [];
            console.log("gettind data report");
            console.log("start", startDate);
            console.log("end", endDate);
            let fechaInicio = new Date(startDate.getTime());
            let fechaFin = new Date(endDate.getTime());
            //fechaInicio.setHours(fechaInicio.getHours() + 1);
            //fechaFin.setHours(fechaFin.getHours() + 1);

            let usertoconsult = { ...user };
            if (isAdmin && adminCurrentUser) {
                usertoconsult.uid = adminCurrentUser;
            }
            if (getUidMaster(usertoconsult.uid)) {
                usertoconsult.uid = getUidMaster(usertoconsult.uid);
            }

            console.log("consultando user", usertoconsult.uid);
            getNominasByRange(usertoconsult.uid, fechaInicio, fechaFin).then(snapshot => {
                console.log("data report", snapshot.docs.length);
                snapshot.forEach(document => {
                    let obj = { ...document.data() };
                    obj.id = document.id;
                    aux.push(obj);
                    obj.inicio = obj.startDate ? moment(obj.startDate.toDate()).format("DD/MM/YYYY") : "";
                    obj.fin = obj.endDate ? moment(obj.endDate.toDate()).format("DD/MM/YYYY") : "";

                })
            }).catch(err => { console.log(err) });
            setDataReport(aux);
        }
    }, [startDate, endDate])

    return (
        <Fragment>
            {
                <div className="row">
                    <div className="col-12">
                        <h3 style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>&nbsp;Módulo de Liquidaciones</h3>
                        <div className="card">
                            <div className="card-header card-header-flex">

                                <div className="d-flex px-3 row justify-content-between align-items-center">
                                    <div className="col-auto pl-0">
                                        <div style={{ display: 'flex' }}>
                                            <button style={{ width: '300px' }} class="boton primBtn add ms-right" onClick={() => handleCreate()}><span>Agregar Liquidación</span></button>
                                            <DateRangePicker
                                                className="form-control"
                                                clearIcon={null}
                                                value={[startDate, endDate]}
                                                onChange={onChangeDaterangeTwoFilter}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-auto pl-0">
                                        <div style={{ display: 'flex' }}>

                                        </div>
                                    </div>


                                    <div>

                                    </div>
                                    <div style={{ height: '46px', padding: '5px' }}>

                                        <DatePicker
                                            className="form-control customdatepicker"
                                            locale="es"
                                            selected={currentDate}
                                            onChange={date => handleDateChanged(date)}
                                            maxDate={addDays(new Date(), 1)}
                                            dateFormat="MMMM yyyy"
                                            showMonthYearPicker
                                        />
                                    </div>

                                </div>
                            </div>
                            <LoadingOverlay
                                active={isLoading}
                                spinner
                                text='Actualizando datos, espere ...'
                            >
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-10">
                                            <HotTable settings={{
                                                colHeaders: true,
                                                rowHeaders: true,
                                                autoRowSize: false,
                                                columnSorting: true,
                                                dropdownMenu: ['filter_by_condition',
                                                    'filter_by_value',
                                                    'filter_action_bar'],
                                                filters: true,
                                                manualColumnResize: true,
                                                // colWidths: [100,100,80,90,90,140,140,160,160,80,120,80,120,90],
                                                //para ancho total                                    
                                                //  minSpareRows: 1,          // almenos una fila
                                                autoColumnSize: true,     // 
                                                //  stretchH: 'all',          // 
                                                //disableVisualSelection: true,
                                                currentRowClassName: 'currentRowHightlight',
                                                licenseKey: "non-commercial-and-evaluation",
                                                language: "es-MX"
                                            }} data={ingresos} colHeaders={true} rowHeaders={false} width="100%" height="75vh" >


                                                <HotColumn className="htCenter htMiddle" readOnly title="Operador" data="operadorName" />
                                                <HotColumn className="htCenter htMiddle" readOnly title="Desde" data="inicio" />
                                                <HotColumn className="htCenter htMiddle" readOnly title="Hasta" data="fin" />
                                                <HotColumn className="htCenter htMiddle" readOnly title="Monto" data="total" />
                                                <HotColumn className="htCenter htMiddle" readOnly title="Estatus" data="estatus" />
                                                {uid && requiereMostrarNumeroLiquidacion.find(r => r == uid) && <HotColumn className="htCenter htMiddle" readOnly title="N.Liquidación" data="numeroLiquidacion" />}
                                                <HotColumn className="htCenter htMiddle" readOnly title="Acciones" data="obj"  >
                                                    <ActionRender hot-renderer handleEditFromId={handleEditFromId} handleDeleteFromId={handleDeleteFromId} handleOpenReport={handleOpenReport} handleOpenReportP={handleOpenReportP} handleDownloadExcel={handleDownloadExcel} />
                                                </HotColumn>

                                            </HotTable>

                                        </div>
                                        <div className="col-2">
                                            <DateRangePicker
                                                className="form-control"
                                                clearIcon={null}
                                                value={[startDate, endDate]}
                                                onChange={onChangeDaterangeTwo}
                                            />
                                            {/*<DatePicker
                                                selected={startDate}
                                                onChange={onChangeDaterange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                inline
                                            />*/}
                                            {datareport ? <ExcelFile element={<button type="button" style={{ marginRight: '10px', marginTop: '10px' }} className="boton secBtn excel ms-right" ><span>Descargar Reporte</span></button>
                                            }>
                                                <ExcelSheet data={datareport} name="Nomina">
                                                    <ExcelColumn label="Operador" value="operadorName" />
                                                    <ExcelColumn label="Desde" value="inicio" />
                                                    <ExcelColumn label="Hasta" value="fin" />
                                                    <ExcelColumn label="Total Conceptos" value="totalConceptos" />
                                                    <ExcelColumn label="Total Fijos" value="totalfijos" />
                                                    <ExcelColumn label="Adicionales" value="totalAdicionales" />
                                                    <ExcelColumn label="Cargos" value="totalCargos" />
                                                    <ExcelColumn label="Salario Base" value="salariobaseFloat" />
                                                    <ExcelColumn label="Salario Diario" value="salariodiarioFloat" />
                                                    <ExcelColumn label="Sueldo IMSS" value="sueldoImssFloat" />
                                                </ExcelSheet>
                                            </ExcelFile> :
                                                <button disabled type="button" style={{ marginRight: '10px', marginTop: '10px' }} className="boton secBtn excel ms-right" ><span>Descargar Reporte</span></button>

                                            }
                                        </div>
                                    </div>

                                </div>


                            </LoadingOverlay>
                        </div>
                    </div>
                    <ModalCreateEdit
                        findRecord={findRecord}
                        currenteditrecrod={currenteditrecrod}
                        currentDate={currentDate}
                        isEdit={isEdit}
                        isOpen={isOpen}
                        uid={uid ? uid : null}
                        uidAltaEdit={uidAltaEdit}
                        adminCurrentUser={adminCurrentUser || null}
                        isAdmin
                        notify={refresh}
                        setLoading={value => setIsLoading(value)}
                        toggle={() => setIsOpen(false)} />
                </div>
            }
        </Fragment>
    );
}
export default CartasPorteContent;
