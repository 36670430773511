import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './components/login';
import ForgotPassword from './components/login/forgotpassword'
import Dashboard from './components/dashboard';
import Costos from './components/costos';
import Ingresos from './components/ingresos';
import Facturas from './components/facturas';
import Users from './components/users';
import Viajes from './components/viajes';
import ViajesNARI from './components/viajes2';
import Viaje from './components/viajes/create';
import Viaje2 from './components/viajes/create2';
import { firebase } from './configs/firebase';
import Activos from './components/activos';
import Operadores from './components/operadores';
import Ubicaciones from './components/ubicaciones';
import CartaPorte from './components/cartaporte';
import CCP from './components/ccp';
import CCPV2 from './components/ccpv2';
import CCPV2LI from './components/ccpv2li';
import Desglose from './components/desgloseccpv2';
import CCPTRASLADO from './components/ccptraslado';
import CCPPREVIEW from './components/ccppreview';
import CCPPREVIEWNEW from './components/ccppreviewnew';
import CFDI from './components/cfdipdf';
import CFDIPreview from './components/cfdipdfpreview';
import CFDIV from './components/cfdivpdf';
import ComplementoPago from './components/complementopago';
import ComplementoPago20 from './components/complementopago20';
import Invoice from './components/innvoice';
import ReportNomina from './components/nomina/reportet';
import ReportNominaP from './components/nomina/reportep';
import ReportNominaMedel from './components/nomina/reportetmedel';

import Planeacion from './components/planeacion';
import CartasPorteCrud from './components/cartasportecrud';
import Nomina from './components/nomina';
import CostoXKm from './components/costoxkm';
import Rentabilidad from './components/rentabilidad';
import Mantenimiento from './components/mantenimiento';
import Servicio from './components/servicio';
import { DBConfig } from './configs/DBConfig';
import { initDB } from 'react-indexed-db';
//import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
//import DashIcon from '@material-ui/icons/List';

initDB(DBConfig);
function App() {

  const PublicRoute = ({ children, ...rest }) => {
    let _Auth;
    if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') !== '')) {
      _Auth = false;
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          _Auth = true;
          localStorage.removeItem('marketpointmanagerid');
          localStorage.removeItem('marketpointmanager-user');
        }
      });
    } else {
      _Auth = true;
    }

    return (
      <Route
        {...rest}
        render={
          (props) => _Auth ? (children) : (<Redirect to='/dashboard' />)
        }
      />
    )
  }

  const PrivateRouteLevel = ({ children, level, section, name, ...rest }) => {
    let accessLevel;
    let aux = false;
    let gerencialdashboard=false;
    
    if (localStorage.getItem('marketpointmanager-user') && (localStorage.getItem('marketpointmanager-user') !== '')) {

      switch (localStorage.getItem('marketpointmanager-user')) {
        case 'user':
          accessLevel = 1;
          if (section === 1 || section === 2 || section === 3) {
            aux = true;
          }
          break;
        case 'gerencia':
          accessLevel = 2;
          if (section === 1 || section === 2 || section === 3) {
            aux = true;
          }
          break;
        case 'administrador':
          accessLevel = 4;
          aux = true;
          break;
        default:
          accessLevel = 0;
          break;
      }
      firebase.auth().onAuthStateChanged(function (user) {
        if (!user) {
          accessLevel = 0;
          aux = false;
          localStorage.removeItem('marketpointmanagerid');
          localStorage.removeItem('marketpointmanager-user');

        }
      });
    }

    let hasAccess;
    (accessLevel > level && aux) ? hasAccess = true : hasAccess = false;

    if (name && name !== "") {


      if (localStorage.getItem('deny')) {
        let auxdeny = localStorage.getItem('deny').split(":");
        if (auxdeny.find(e => e === name)) {
          hasAccess = false;
        } else {
          hasAccess = true;
        }


      } else {
        hasAccess = true;
      }

    }


    return (
      <Route
        {...rest}
        render={
          (props) => hasAccess ? (children) : (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
        }
      />
    )
  }

  return (
    <Router>
      <Switch>
        <PublicRoute path='/' exact>
          <Login />


        </PublicRoute>
        <PublicRoute path='/forgotpassword' exact>
          <ForgotPassword />
        </PublicRoute>
        <PrivateRouteLevel level={0} path='/dashboard' section={1}  >
          <Dashboard />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/costoxkm' section={1} name="costoxkm">
          <CostoXKm />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/rentabilidad' section={1} name="rentabilidad">
          <Rentabilidad />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/costos' section={1} name="costos">
          <Costos />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ingresos' section={1} name={"ingresos"} >
          <Ingresos />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/activos' section={1} name="activos">
          <Activos />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/planeacion' section={1} name="planeacion">
          <Planeacion />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/operadores' section={1} >
          <Operadores />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ubicaciones' section={1} >
          <Ubicaciones />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/facturas' section={1} name="facturas">
          <Facturas />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/viajes' section={1} name="viajes" >
          <Viajes />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/viajesc' section={1} name="viajes" >
          <ViajesNARI />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/viaje' section={1} >
          <Viaje2 />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/viaje2' section={1} >
          <Viaje />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/viaje/:idparam' section={1} >
          <Viaje2 />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/viaje2/:id' section={1} >
          <Viaje />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={3} path='/users' section={9} >
          <Users />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/cporte/:id' section={1} >
          <CartaPorte />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ccp/:id' section={1} >
          <CCP />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ccpv2/:id' section={1} >
          <CCPV2 />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ccpv2li/:id' section={1} >
          <CCPV2LI />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/desglose/:id' section={1} >
          <Desglose />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ccptraslado/:id' section={1} >
          <CCPTRASLADO />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/ccpreview/:id' section={1} >
          <CCPPREVIEW />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/preview/:id' section={1} >
          <CCPPREVIEWNEW />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/complementopago/:id' section={1} >
          <ComplementoPago />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/complementopago20/:id' section={1} >
          <ComplementoPago20 />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/cfdi/:id' section={1} >
          <CFDI />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/previewcfdi/:id' section={1} >
          <CFDIPreview />
        </PrivateRouteLevel>
        
        <PrivateRouteLevel level={0} path='/cfdiv/:id' section={1} >
          <CFDIV />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/invoice/:id' section={1} >
          <Invoice />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/reportnom/:id' section={1} >
          <ReportNomina />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/reportnomp/:id' section={1} >
          <ReportNominaP />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/reporttmedel/:id' section={1} >
          <ReportNominaMedel />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} path='/reporttf/:id' section={1} >
          <ReportNominaMedel />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/cartasporte' section={1} >
          <CartasPorteCrud />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/nomina' section={1} name="nomina">
          <Nomina />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/mantenimiento' section={1} name="mantenimiento">
          <Mantenimiento />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/servicio' section={1} >
          <Servicio />
        </PrivateRouteLevel>
        <PrivateRouteLevel level={0} exact path='/servicio/:idparam' section={1} >
          <Servicio />
        </PrivateRouteLevel>
      </Switch>
    </Router>
  );
}

export default App;

